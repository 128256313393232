













































































































































































































































































import Vue from 'vue';
// models
import { RemonadeTimezone, Timezone, timezones } from '@/service/Timezones';
// conmonents
// store
import { settingViewModule } from '@/store/viewModule/settings/settingViewModule';
import { teamModule } from '@/store/dataModule/team/teamModule';
import { commonModule } from '@/store/viewModule/common/commonModule';
import { alertModule } from '@/store/viewModule/common/alertModule';
import { TeamResponse, UserResponse, UserType, WhitelistResponse } from 'remonade-api/lib';
import { sendEvent } from '@/service/GoogleAnalyticsService';

export interface DataType {
  suspendingUser: UserResponse | null;
  invitingEmails: string[];
  addingDomains: string[];
  suspendUserModal: boolean;
  inviteUserModal: boolean;
  addDomainModal: boolean;
}

export default Vue.extend({
  name: 'TeamUsersSettingContainer',

  data() {
    return {
      suspendingUser: null as UserResponse | null,
      invitingEmails: [''] as string[],
      addingDomains: [''] as string[],
      suspendUserModal: false as boolean,
      inviteUserModal: false as boolean,
      addDomainModal: false as boolean,
    };
  },

  components: {
  },

  computed: {
    user(): UserResponse | null {
      return settingViewModule.user;
    },
    team(): TeamResponse | null {
      return settingViewModule.team;
    },
    whitelist(): WhitelistResponse[] {
      return settingViewModule.whitelist;
    },
    teamUsers(): UserResponse[] {
      return settingViewModule.teamUsers;
    },
    teamUsersList(): UserResponse[] {
      return settingViewModule.teamUsersList;
    },
    invitationLink(): string {
      if (this.team) {
        return `${location.protocol}//${location.host}/invitation?teamId=${this.team.teamId}`;
      } else {
        return '';
      }
    },
    canInvite(): boolean {
      if (this.team) {
        const userLimit = this.team.plan.userLimit;
        if (userLimit < 0) {
          return true;
        }
        return this.teamUsers.length < userLimit;
      }
      return false;
    },
    inviteButtonDisabled(): boolean {
      if (this.user) {
        const validEmails = this.invitingEmails
                              .filter((email) => email !== '' && email.trim() !== '')
                              .filter((email) => this.isEmailValid(email))
                              .filter((email) => this.isEmailNotExist(email))
                              .filter((email) => {
                                return email !== this.user!.email;
                              });
        return validEmails.length === 0;
      } else {
        return true;
      }
    },
  },

  methods: {
    async updateUserType(user: UserResponse, role: string) {
      const newUser = {...user};
      newUser.userType = role as UserType;
      commonModule.setLoader(true);
      await settingViewModule.updateUserRole(newUser);
      commonModule.setLoader(false);
    },
    async resend(user: UserResponse) {
      commonModule.setLoader(true);
      await settingViewModule.inviteUser({ teamId: user.teamId, email: user.email });
      commonModule.setLoader(false);
      alert(this.$tc('resentInvitation'));
    },
    gotoPlan() {
      settingViewModule.selectChannel('PLAN');
    },
    openSuspendModal(user: UserResponse) {
      this.suspendingUser = user;
      this.suspendUserModal = true;
    },
    async suspendUser() {
      if (this.suspendingUser) {
        commonModule.setLoader(true);
        await settingViewModule.suspendUser(this.suspendingUser);
        this.suspendUserModal = false;
        this.suspendingUser = null;
        commonModule.setLoader(false);
      }
    },
    onInvitationLinkCopy() {
      window.dataLayer.push({'invitationTypeButtonClick': 'copyUrlButton'})
      alert(this.$tc('copiedInvitationLink'));
      sendEvent('invitation', {
        link: true,
      });
      this.$intercom.trackEvent('task_copy', {
        link: true,
      });
    },
    onInvitationLinkError() {
      alert(this.$tc('copyFailed'));
    },
    focusEmailInput(index: number) {
      if (this.team) {
        if (this.team.plan.userLimit > 0) {
          if (
            index === this.invitingEmails.length - 1 &&
            (this.invitingEmails.length + this.teamUsers.length) < this.team.plan.userLimit
          ) {
            this.invitingEmails.push('');
          }
        } else {
          if (index === this.invitingEmails.length - 1) {
            this.invitingEmails.push('');
          }
        }
      }
    },
    focusDomainInput(index: number) {
      if (index === this.addingDomains.length - 1) {
        this.addingDomains.push('');
      }
    },
    isEmailValid(email: string) {
      if (!email) { return false; }
      const emailRegExp = /^[A-Za-z0-9]{1}[A-Za-z0-9_._-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;
      return emailRegExp.test(email);
    },
    isEmailNotExist(email: string) {
      const length = this.teamUsersList.filter((user) => user.email === email).length;
      if (length < 1) {
        return true;
      } else {
        return false;
      }
    },
    async inviteUsers() {
      const emails = this.invitingEmails.filter((email) => email !== '' && email !== ' ');
      emails.filter((email) => !this.isEmailValid(email)).forEach((email) => {
        alert(`${this.$t('emailInvalidAlertPre')} ${email} ${this.$t('emailInvalidAlertSuf')}`);
      });
      emails.filter((email) => email === this.user!.email).forEach((email) => {
        alert(`${this.$t('emailMineAlertPre')} ${email} ${this.$t('emailMineAlertSuf')}`);
      });
      emails.filter((email) => !this.isEmailNotExist(email)).forEach((email) => {
        alert(`${email} is already invited.`);
      });
      const validEmails = this.invitingEmails.filter((email) => this.isEmailNotExist(email) && this.isEmailValid(email) && email !== this.user!.email);
      if (validEmails.length === emails.length) {
        const uniqueEmails: string[] = [];
        validEmails.forEach((email) => {
          if (uniqueEmails.indexOf(email) < 0) {
            uniqueEmails.push(email);
          }
        });
        commonModule.setLoader(true);
        await settingViewModule.inviteUsers(uniqueEmails);
        commonModule.setLoader(false);
        this.inviteUserModal = false;
        sendEvent('invitation', {
          link: false,
        });
        this.$intercom.trackEvent('task_copy', {
          link: false,
        });
      }
    },
    async addDomains() {
      const domains = this.addingDomains.filter((domain) => domain !== '' && domain !== ' ');
      domains.filter((domain) => this.whitelist.find((d) => d.domain === domain)).forEach((domain) => {
        alert(`${domain} is already invited.`);
      });
      const validDomains = domains.filter((domain) => !this.whitelist.find((d) => d.domain === domain));
      if (validDomains.length === domains.length) {
        const uniqueDomains: string[] = [];
        validDomains.forEach((domain) => {
          if (uniqueDomains.indexOf(domain) < 0) {
            uniqueDomains.push(domain);
          }
        });
        const newValue = uniqueDomains.map((v) => {
          if (typeof(v) === 'string') {
            if (this.team) {
              const domain: WhitelistResponse = {
                teamId: this.team.teamId,
                domain: v,
              };
              return domain;
            }
          } else {
            return v;
          }
        });
        commonModule.setLoader(true);
        await settingViewModule.createDomain(newValue);
        commonModule.setLoader(false);
        this.addDomainModal = false;
      }
    },
    async removeDomain(domain: WhitelistResponse) {
      commonModule.setLoader(true);
      await teamModule.deleteDomainWhitelist({ teamId: domain.teamId, domain: domain.domain });
      commonModule.setLoader(false);
    },
  },

  watch: {
    inviteUserModal() {
      if (!this.inviteUserModal) {
        this.invitingEmails = [''];
      } else {
        window.dataLayer.push({'invitationTypeButtonClick': 'emailButton'})
      }
    },
    addDomainModal() {
      if (!this.addDomainModal) {
        this.addingDomains = [''];
      }
    },
  },
});
